<template>
  <!-- <div>
    <div class="app-header"></div>
    <div class="app-content">
      <router-view />
    </div>
  </div> -->
  <a-layout>
    <a-layout-header :style="{ position: 'fixed', zIndex: 999, width: '100%' }">
      <div class="nav-header-main">
        <div class="nav-header-left">
          <div class="logo">
            <a href="">
              <img src="@/assets/images/logo.png" alt="" />
              <span class="title">御辕堂素材库</span>
              <!-- 御辕堂素材库 -->
            </a>
          </div>
        </div>
        <!-- theme="dark" -->
        <div style="flex-grow: 1">
          <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal" :style="{ lineHeight: '64px' }">
            <a-menu-item key="/home">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/home"> 素材 </router-link>
            </a-menu-item>
            <a-menu-item key="/project">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/project">项目</router-link>
            </a-menu-item>
            <!-- <a-menu-item key="3">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/wish">世界</router-link>
            </a-menu-item> -->
          </a-menu>
        </div>
        <div style="margin-right: 25px; line-height: 70px">
          <a-badge :count="$store.state.account.uploadList.length">
            <span> <cloud-upload-outlined style="font-size: 18px" /> </span></a-badge>
        </div>
        <div style="float: right">
          {{ sc_nickename }}
          <a-avatar>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content :style="{ paddingTop: '65px', margin: '16px' }">
      <!-- <a-breadcrumb :style="{ margin: '16px 0' }">
        <a-breadcrumb-item>主页</a-breadcrumb-item>

        <a-breadcrumb-item>素材</a-breadcrumb-item>
      </a-breadcrumb> -->
      <div :style="{
        background: '#fff',

        minHeight: '500px',
      }">
        <router-view />
      </div>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center' }">
      御辕堂 ©2022
    </a-layout-footer>
  </a-layout>
</template>T

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import {
  AppstoreOutlined,
  UserOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    AppstoreOutlined,
    UserOutlined,
    CloudUploadOutlined,
  },
  setup() {
    const router = useRouter();
    const selectedKeys = ref([]);
    const sc_nickename = ref('');
    onMounted(() => {
      setSelectKey();
    });
    sc_nickename.value = localStorage.getItem('sc_nickename');
    const setSelectKey = () => {
      // alert();
      const currRoute = router.currentRoute;

      selectedKeys.value = [`${currRoute.value.path.replace("_ch", "")}`];
    };
    return {
      selectedKeys,
      sc_nickename
    };
  },
});
</script>
<style lang="less"  scoped>
.ant-layout-header {
  @media screen and (max-width: 600px) {
    padding: 0 5px;
  }
}
</style>